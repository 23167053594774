import React, { useEffect, useState } from 'react';
import { updateCustomerInfo } from '../../services/customer.service';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import { getAllDoctors, getFieldList } from '../../services/user.service';
import { Button, makeStyles, TextField, Typography, Grid, Select, MenuItem, InputLabel, Switch } from '@material-ui/core';
import { includes, isEmpty } from 'lodash';
import DatePicker from '../DatePicker/DatePicker.component';

const UpdateCustomerDetails = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [errorInputMessage, setErrorInputMessage] = useState(null);
  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 10,
      width: '100%'
    },
    select: {
      width: '100%'
    },
    formControl: {
      width: '100%'
    }
  }));
  const classes: any = useStyles();
  const { mobile_number, pap_id, nric, patient_id, patient_name, mykad_number, scheme, 
    email_address, registered_by_user, chosen_language, diagnosis, treating_physician, 
    selected_dosage, program_id, selected_sku, hospital_name, dispensing_channel, 
    verified_on, manually_enrolled, dosage_frequency } = data;
  const [patientDetails, setPatientDetails] = useState({
    mobile_number: mobile_number == "null" ? null : mobile_number,
    nric,
    patient_name, mykad_number, scheme,
    email_address: email_address == "null" ? null : email_address,
    registered_by_user,
    hospital_name,
    dispensing_channel,
    diagnosis,
    chosen_language,
    treating_physician,
    pap_id,
    selected_sku,
    selected_dosage,
    verified_on,
    dosage_frequency,
    manually_enrolled,
    manual_digital: manually_enrolled ? "manual" : "digital"
  });
  const [listOfDoctors, setListOfDoctors] = useState<any>();
  const [listOfDispensingChannel, setListOfDispensingChannel] = useState<any>();
  const [listOfHospital, setListOfHospital] = useState<any>();
  const programId = program_id;
  let nodeEnv = 'develop';
  if (programId) {
    const splittedProgramId = programId.split('-');
    if (splittedProgramId && splittedProgramId.length) {
      nodeEnv = splittedProgramId.pop();
    }
  }
  const currentDate = new Date();

  useEffect(() => {
    const getDoctors = async () => {
      const { result } = await getAllDoctors({
        program_id: includes(programId, 'lets-get-started-fraizeron') ? `one-novartis-${nodeEnv}` : programId,
      });

      result.unshift({ label: 'NA', value: 'NA' });
      setListOfDoctors(result);
    }

    const getAllDispensingChannel = async () => {
      const { result } = await getFieldList({
        program_id: includes(programId, 'lets-get-started-fraizeron') ? `one-novartis-${nodeEnv}` : programId,
        name: 'outlet_name'
      });

      let dispensingChannel = [];
      if (result && result.field_type_options) {
        dispensingChannel = result.field_type_options.select_options;
        dispensingChannel.unshift({ label: 'NA', value: 'NA' });
      }

      setListOfDispensingChannel(dispensingChannel);
    }

    const getAllHospital = async () => {
      const { result } = await getFieldList({
        program_id: includes(programId, 'lets-get-started-fraizeron') ? `one-novartis-${nodeEnv}` : programId,
        name: 'hospital'
      });

      let hospitals = [];
      if (result && result.field_type_options) {
        hospitals = result.field_type_options.select_options;
        hospitals.unshift({ label: 'NA', value: 'NA' });
      }

      setListOfHospital(hospitals);
    }

    getDoctors();
    getAllDispensingChannel();
    getAllHospital();
  }, []);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const res = await updateCustomerInfo({
        program_id,
        customer_id: patient_id,
        payload: {
          mobile_number: patientDetails.mobile_number || undefined,
          registered_by_user: patientDetails.registered_by_user,
          metadata: {
            nric: patientDetails.nric,
            patient_name: patientDetails.patient_name, 
            mykad_number: patientDetails.mykad_number, 
            scheme: patientDetails.scheme,
            email_address: patientDetails.email_address,
            hospital_name: patientDetails.hospital_name,
            dispensing_channel: patientDetails.dispensing_channel,
            diagnosis: patientDetails.diagnosis,
            chosen_language: patientDetails.chosen_language,
            treating_physician: patientDetails.treating_physician,
            pap_id: patientDetails.pap_id,
            selected_sku: patientDetails.selected_sku,
            selected_dosage: patientDetails.selected_dosage,
            dosage_frequency: patientDetails.dosage_frequency,
            verified_on: patientDetails.verified_on,
            manually_enrolled: patientDetails.manual_digital === "digital" || true,
          }
        }
      })

      if (res) {
        setOpenModal(false);
        setConfirmation(res.message);
      }

      setLoading(false);
    } catch(err) {
      if (err && err.inputs && err.message === 'ValidationError') {
        setErrorInputMessage(err.inputs);
      } else if (err && err.message) {
        alert(err.message);
      }
    } finally {
      setLoading(false);
    }
  }

  const onClick = async () => {
    setOpenModal(true);
  };

  const onFieldInput = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;

      // if (name === "registered_by_user") {
      //   if (listOfDoctors && listOfDoctors.length) {
      //     const doctor = listOfDoctors.find((doctor) => doctor.id === value);
      //     if (doctor) {
      //       setPatientDetails(prevState => ({
      //         ...prevState,
      //         ['treating_physician']: doctor.full_name,
      //       }));
      //     }
      //   }
      // }

      setPatientDetails(prevState => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setPatientDetails(prevState => ({
        ...prevState,
        ['verified_on']: e,
      }));
    }
  }

  const formContent = (
    <>
      { program_id && program_id.includes('lets-get-started-fraizeron') ? 
      <>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <TextField 
            required={true}
            label='Full Name'
            name='patient_name' 
            defaultValue={patientDetails.patient_name}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
            />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.patient_name : '' }
          </Typography>
        </Grid>
        <Grid item xs={6}>
            <TextField 
              required={true}
              label='PAP ID'
              name='pap_id'
              defaultValue={patientDetails.pap_id}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
              />
            <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.pap_id : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid item
        container
        xs={12}
        spacing={2}>
        <Grid item xs={6}>
          <TextField  
              disabled={!isEmpty(mobile_number) && mobile_number != "null"}
              label='Mobile Number'
              required={true}
              name='mobile_number' 
              defaultValue={patientDetails.mobile_number}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput} 
              placeholder='60XXXXXXXXX'
            />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.mobile_number : '' }
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField 
            required={true}
            label='MyKad Number / Passport'
            name='mykad_number' 
            defaultValue={patientDetails.mykad_number}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
            />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.mykad_number : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
         <Grid 
          item xs={12}
        >
          <TextField  
            disabled={!isEmpty(email_address) && email_address != "null"}
            label='Email Address'
            name='email_address' 
            defaultValue={patientDetails.email_address}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
          />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.email_address : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
         item
         container
         xs={12}
         spacing={2}
      >
        <Grid item xs={6}>
            <InputLabel>Enrolled By</InputLabel>
            <Select 
              required={true}
              label='Enrolled By'
              name="registered_by_user"
              variant='outlined' 
              defaultValue={patientDetails.registered_by_user}
              className={classes.field}
              onChange={onFieldInput}
            >
              {
                listOfDoctors && listOfDoctors.length ? listOfDoctors.map(listOfDoctor => {
                  return (
                    <MenuItem className={classes.menuItem} key={listOfDoctor.id} value={listOfDoctor.id}>
                      {listOfDoctor.full_name}
                    </MenuItem>
                  )
                }) : <></>
              }
            </Select>
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.registered_by_user : '' }
          </Typography>
        </Grid>
        <Grid item xs={6}>
            <InputLabel>Chosen Language</InputLabel>
            <Select 
              required={true}
              label='Chosen Language'
              name="chosen_language"
              variant='outlined' 
              defaultValue={patientDetails.chosen_language}
              className={classes.field}
              onChange={onFieldInput}
            >
              <MenuItem className={classes.menuItem} key="en" value="en">
                English
              </MenuItem>
              <MenuItem className={classes.menuItem} key="my" value="my">
                Malay
              </MenuItem>
              <MenuItem className={classes.menuItem} key="cn" value="cn">
                Chinese
              </MenuItem>
            </Select>
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.chosen_language : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <InputLabel>Dosage</InputLabel>
          <Select 
            required={true}
            label='Dosage'
            name="selected_dosage"
            variant='outlined' 
            defaultValue={patientDetails.selected_dosage}
            className={classes.field}
            onChange={onFieldInput}
          >
              <MenuItem className={classes.menuItem} key="fraizeron_150mg_1-box" value="fraizeron_150mg_1-box">
                Fraizeron 150mg
              </MenuItem>
              <MenuItem className={classes.menuItem} key="fraizeron_300mg_1-box" value="fraizeron_300mg_1-box">
                Fraizeron 300mg
              </MenuItem>
              <MenuItem className={classes.menuItem} key="fraizeron_75mg_1-box" value="fraizeron_75mg_1-box">
                Fraizeron 75mg
              </MenuItem>
            </Select>
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.selected_dosage : '' }
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>SKU</InputLabel>
          <Select 
              required={true}
              label='SKU'
              name="selected_sku"
              variant='outlined' 
              defaultValue={patientDetails.selected_sku}
              className={classes.field}
              onChange={onFieldInput}
            >
              <MenuItem className={classes.menuItem} key="fraizeron_150mg_auto_injector_1" value="fraizeron_150mg_auto_injector_1">
                  Fraizeron pre-filled pen 150mg (x1)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="fraizeron_150mg_auto_injector_2" value="fraizeron_150mg_auto_injector_2">
                Fraizeron pre-filled pen 150mg (x2)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="fraizeron_300mg_auto_injector_1" value="fraizeron_300mg_auto_injector_1">
                Fraizeron pre-filled pen 300mg (x1)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="fraizeron_150mg_vial_1" value="fraizeron_150mg_vial_1">
                Fraizeron 150mg vial (X1)
              </MenuItem>
            </Select>
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.selected_sku : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <InputLabel>Scheme</InputLabel>
          <Select 
            required={true}
            label='Scheme'
            name="scheme"
            variant='outlined' 
            className={classes.field}
            onChange={onFieldInput}
            defaultValue={patientDetails.scheme}
          >
              <MenuItem className={classes.menuItem} key="4+2" value="4+2">
                4+2
              </MenuItem>
              <MenuItem className={classes.menuItem} key="7+5" value="7+5">
                7+5
              </MenuItem>
              <MenuItem className={classes.menuItem} key="5+7" value="5+7">
                5+7
              </MenuItem>
              <MenuItem className={classes.menuItem} key="3+3" value="3+3">
                3+3
              </MenuItem>
              <MenuItem className={classes.menuItem} key="6+6" value="6+6">
                6+6
              </MenuItem>
              <MenuItem className={classes.menuItem} key="8+4" value="8+4">
                8+4
              </MenuItem>
          </Select>
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.scheme : '' }
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Dosage Frequency</InputLabel>
          <Select 
            required={true}
            label='Dosage Frequency'
            name="dosage_frequency"
            variant='outlined' 
            className={classes.field}
            onChange={onFieldInput}
          >
              <MenuItem className={classes.menuItem} key="Monthly" value="Monthly">
                Monthly
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Bi-weekly" value="Bi-weekly">
                Bi-weekly
              </MenuItem>
          </Select>
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.dosage_frequency : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <InputLabel>Hospital</InputLabel>
          <Select 
            required={true}
            label='Hospital'
            name="hospital_name"
            variant='outlined' 
            defaultValue={patientDetails.hospital_name}
            className={classes.field}
            onChange={onFieldInput}
          >
             {
                listOfHospital && listOfHospital.length ? listOfHospital.map(hospital => {
                  return <MenuItem className={classes.menuItem} key={hospital.value} value={hospital.value}>
                  {hospital.label}
                </MenuItem>
                }) : <></>
              }
            </Select>
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.hospital_name : '' }
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Dispensing Channel</InputLabel>
          <Select 
              required={true}
              label='Dispensing Channel'
              name="dispensing_channel"
              variant='outlined' 
              defaultValue={patientDetails.dispensing_channel}
              className={classes.field}
              onChange={onFieldInput}
            >
              {
                listOfDispensingChannel && listOfDispensingChannel.length ? listOfDispensingChannel.map(dispensingChannel => {
                  return <MenuItem className={classes.menuItem} key={dispensingChannel.value} value={dispensingChannel.value}>
                  {dispensingChannel.label}
                </MenuItem>
                }) : <></>
              }
            </Select>
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.dispensing_channel : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <InputLabel>Diagnosis</InputLabel>
          <Select 
              required={true}
              label='Diagnosis'
              name="diagnosis"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
              defaultValue={patientDetails.diagnosis}
            >
              <MenuItem className={classes.menuItem} key="Plaque Psoriasis (PsO)" value="Plaque Psoriasis (PsO)">
                Plaque Psoriasis (PsO)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Psoriatic Arthritis (PsA)" value="Psoriatic Arthritis (PsA)">
                Psoriatic Arthritis (PsA)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Juvenile Psoriatic Arthritis (JPsA)" value="Juvenile Psoriatic Arthritis (JPsA)">
                Juvenile Psoriatic Arthritis (JPsA)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Enthesitis-Related Arthritis (ERA)" value="Enthesitis-Related Arthritis (ERA)">
                Enthesitis-Related Arthritis (ERA)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Ankylosing Spondylitis (AS)" value="Ankylosing Spondylitis (AS)">
                Ankylosing Spondylitis (AS)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Non-radiographic axial spondyloarthritis (nr-axSpA)" value="Non-radiographic axial spondyloarthritis (nr-axSpA)">
                Non-radiographic axial spondyloarthritis (nr-axSpA)
              </MenuItem>
              <MenuItem className={classes.menuItem} key="Hidradenitis Suppurativa (HS)" value="Hidradenitis Suppurativa (HS)">
                Hidradenitis Suppurativa (HS)
              </MenuItem>
            </Select>
            <Typography
              variant='caption'
              display='block'
              gutterBottom
              color='error'>
              { errorInputMessage ? errorInputMessage.diagnosis : '' }
            </Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Manual/Digital</InputLabel>
          <Select 
              required={true}
              label='Manual/Digital'
              name="manual_digital"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
              defaultValue="manual"
            >
              <MenuItem className={classes.menuItem} key="manual" value="manual">
                Manual
              </MenuItem>
              <MenuItem className={classes.menuItem} key="digital" value="digital">
                Digital
              </MenuItem>
            </Select>
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.manual_digital : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <TextField 
            required={true}
            label='Treating Physician'
            name='treating_physician'
            defaultValue={patientDetails.treating_physician}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
            />
          <Typography
              variant='caption'
              display='block'
              gutterBottom
              color='error'>
              { errorInputMessage ? errorInputMessage.treating_physician : '' }
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>T&C Acceptance Date</InputLabel>
          <DatePicker
            required={true}
            onChange={onFieldInput}
            name='verified_on'
            className={classes.field} 
            maxDate={currentDate}
            defaultValue={currentDate}
            value={patientDetails.verified_on}
          />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.verified_on : '' }
          </Typography>
        </Grid>
      </Grid>
      </>
      :
        <Grid
        item
        container
        xs={12}
        spacing={2}
        >
          <Grid item xs={6}>
              <TextField  
                label='Mobile Number'
                required={true}
                name='mobile_number' 
                defaultValue={patientDetails.mobile_number}
                variant='outlined'
                className={classes.field}
                onChange={onFieldInput}
              />
          </Grid>
          <Grid item xs={6}>
            <TextField 
              required={true}
              label='NRIC Number'
              name='nric' 
              defaultValue={patientDetails.nric}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
              />
          </Grid>
        </Grid>
      }
      
      <Typography
        variant='caption'
        display='block'
        gutterBottom
        color='error'
      >
        { errorMessage }
      </Typography>
    </>
  );

  const handleClose = (reload) => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);
    if (reload) {
      window.location.reload();
    }
  }

  const formButtons = (
    <>
      <Button onClick={() => handleClose(false)} variant='contained'>
        Close
      </Button>
      <Button onClick={onSubmit} variant='contained' color='secondary'>
      Submit
      </Button>
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog
      key='update-customer-details'
      open={openModal}
      title = 'Update Customer Details'
      content = {formContent} 
      children = {formButtons} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(false)} 
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = 'Customer details successfully updated!' 
      children = {(
        <Button onClick={() => handleClose(true)} variant='contained'>
          Close
        </Button>
      )} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(true)} 
      />
     <Button variant="contained" onClick={onClick} style={{ marginRight: 8 }}>Update Details</Button>
    </>
  )
}
export default UpdateCustomerDetails;
